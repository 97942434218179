
import { calcLevelCTW } from './calc_level_ctw';
import Typography from '@mui/material/Typography';


// in JS objects insertion order is preserved! so dicts act like lists!!!!

export const experimental_data = {
    experimental: {        
        label: "Experimental",
        levelType: "CTW",
        levelFn: calcLevelCTW,

        exercises: {
            raweda: {
                label: "Raw EDA",
                level: 1,
                eda: true,
        
                type: "RawEda",
                params: {
                    showWaves: false, 
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1,
        
                },
        
            },        

            video_test: {
                label: "Video test",
                level: 1,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "media/hls/master.m3u8",
                },
            },

            shrink: {
                label: "Stressor images",
                level: 1,
                eda: true,
                // showTree: true,
                // adjustDifficulty: true,
        
                type: "ShrinkImage",
                params: {
                    // backgroundImage: "/media/lelegzos.png",

                    storage_key: "shrink",
                    shrink_speed: 0.3,
            
                    //bgImages: ["/media/masks/maszk2.jpg", "/media/masks/maszk3.jpg", "/media/masks/maszk4.jpg", "/media/masks/maszk5.jpg", "/media/masks/maszk6.jpg", "/media/masks/maszk7.jpg", "/media/masks/maszk8.jpg",],
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1,
        
                },

                video_instruction: {
                    en: "media/hls/master.m3u8"
                },

                instruction: {
                    en: (
                        <div>
                            <Typography variant="body1" align='justify' color="text.primary">
                                <br></br>
                                You will select a series of images that you find stressful. Your task is to focus on 
                                the image and try to relax. If you succeed, the image will shrink. 
                                <br></br>
                                <br></br>
                                You can search the Internet for images. Once you find one, right-click and "copy the image address" to the
                                clipboard. Then click the "Add image" button.
                                <br></br>
                                <br></br>
                                You have to select at least 5 images.
                                <br></br>
                                <br></br>
                                The images are not sent to the server, they are stored and processed locally on your device. 
                                If this is not your own device, you can delete the images after the exercise by going
                                back to the exercise and clicking the "remove image" button.
                                <br></br>
                            </Typography>

                        </div>
                    ),
                }
        
            },
        
            proba1: {
                label: "Próba 1",
                level: 1,
                eda: true,
                // adjustVolume: true,
                // adjustDifficulty: true,
        
                type: "Waves",
                params: {
                    tree: true,
                    backgroundImage: "/media/moon.jpg",
                    duration: 120000,
                    point_mult: 1,
                    // l_incr: 1,
                    // l_decr: 1,
                    // r_incr: 1,
                    // r_decr: 1,
        
                    //backgroundSound: "./media/calm.mp3",
                    periodic: 
                        {
                            sound: "./media/alert.mp3",
                            dt: 10000
                        }
                },
        
            },
        
            proba2: {
                label: "Próba 2",
                level: 1,
                eda: true,
        
                type: "Waves",
                params: {
                    duration: 180000,
                    backgroundImage: "/media/00szinkombinacioJO_5.jpg",
        
                    point_mult: 1,
                    // l_incr: 1,
                    // l_decr: 1,
                    // r_incr: 1,
                    // r_decr: 1
                },
        
            },
        
            proba3: {
                label: "Próba 3",
                level: 1,
                eda: true,
        
                type: "WaveUpdown",
                params: {
                    backgroundImage: "/media/moon.jpg",
                    visual: {
                        incr: ["/media/incr/fire1.jpg", "/media/incr/fire2.jpg", "/media/incr/fire3.jpg", "/media/incr/fire4.jpg", "/media/incr/fire5.jpg", "/media/incr/fire6.jpg",],
                        decr: ["/media/decr/water1.jpg", "/media/decr/water2.jpg", "/media/decr/water3.jpg", "/media/decr/water4.jpg", "/media/decr/water5.jpg", "/media/decr/water6.jpg", "/media/decr/water7.jpg", "/media/decr/water8.jpg",],
                    },
                    duration: 180000,
                    point_mult: 1,
                    l_incr: 2,
                    l_decr: 1,
                    r_incr: 2,
                    r_decr: 1,
        
                },
        
            },

            proba4: {
                label: "Próba 4",
                level: 1,
                eda: true,
        
                type: "Waves",
                params: {
                    tree: true,
                    backgroundImage: "/media/desert4.jpg",
                    duration: 180000,
                    point_mult: 1,
                    // l_incr: 2,
                    // l_decr: 1,
                    // r_incr: 2,
                    // r_decr: 1
                },
        
            },
        
            proba5: {
                label: "Próba 5",
                level: 1,
                eda: true,
                // adjustVolume: true,
                // adjustDifficulty: true,
        
                type: "Waves",
                params: {
                    tree: true,
                    backgroundImage: "/media/moon.jpg",
                    duration: 120000,
                    point_mult: 1,
                    // l_incr: 1,
                    // l_decr: 1,
                    // r_incr: 1,
                    // r_decr: 1,
        
                    //backgroundSound: "./media/calm.mp3",
                    periodic: 
                        {
                            sound: "./media/alert.mp3",
                            dt: 10000
                        }
                },
        
            },
        
            
        }
    }
}
