import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const VerifyEmail = () => {
    const [searchParams] = useSearchParams();
    const [status, setStatus] = useState('loading'); // 'loading', 'success', 'error'
    const token = searchParams.get('token');

    useEffect(() => {
        const verifyToken = async () => {
            if (!token) {
                setStatus('error');
                return;
            }

            console.log('Verifying token:', token);

            try {
                const resp = await fetch('/api/verify-email', {
                    method: 'POST',
                    body: JSON.stringify({ token: token }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                console.log('Verification response:', resp);

                if (resp.ok) {
                    setStatus('success');
                } else {
                    setStatus('error');
                }
            } catch (error) {
                console.error('Verification failed:', error);
                setStatus('error');
            }
        };

        verifyToken();
    }, [token]);

    if (status === 'loading') {
        return <div>Verifying token...</div>;
    }

    if (status === 'success') {
        // add a button that takes you to the login page
        return (
            <div>
                <h2>E-mail verified!</h2>
                <p>
                    You can now <a href="/">login</a> to your account.
                </p>
            </div>
        );



    }

    return <div>Invalid or missing token</div>;
};

export default VerifyEmail;
