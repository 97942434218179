


import { calcLevelCTWkids } from './calc_level_ctw_kids';


export const kids_ctw_data = {
    kids_ctw: {
        label: "Calm The Waves Kids",
        levelType: "CTWKIDS",
        levelFn: calcLevelCTWkids,
        exercises: {

            kids_ctw_basic: {
                label: "CTW Kids Beginner",
                level: 1,
                eda: true,


                type: "Waves",
                params: {
                    duration: 300000,
                    backgroundImage: "/media/00szinkombinacioJO_5.jpg",

                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },

            },


            kids_ctw_flower: {
                label: "CTW Kids Flower (beginner)",
                level: 2,
                eda: true,


                type: "Waves",
                params: {
                    flower: true,
                    backgroundImage: "/media/tree.jpg",
                    duration: 300000,
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },

            },



            kids_ctw_updown: {
                label: "CTW Kids Up-Down",
                level: 3,
                eda: true,
                type: "WaveUpdown",
                params: {
                    backgroundImage: "/media/moon.jpg",
                    visual: {
                        incr: ["/media/incr/fire1.jpg", "/media/incr/fire2.jpg", "/media/incr/fire3.jpg", "/media/incr/fire4.jpg", "/media/incr/fire5.jpg", "/media/incr/fire6.jpg",],
                        decr: ["/media/decr/water1.jpg", "/media/decr/water2.jpg", "/media/decr/water3.jpg", "/media/decr/water4.jpg", "/media/decr/water5.jpg", "/media/decr/water6.jpg", "/media/decr/water7.jpg", "/media/decr/water8.jpg",],
                    },
                    duration: 180000,
                    point_mult: 1,
                    l_incr: 2,
                    l_decr: 1,
                    r_incr: 2,
                    r_decr: 1,

                },

            },

            kids_ctw_tree: {
                label: "CTW Kids Tree",
                level: 4,
                eda: true,
                type: "Waves",
                params: {
                    tree: true,
                    backgroundImage: "/media/desert4.jpg",
                    duration: 600000,
                    point_mult: 1,
                    l_incr: 2,
                    l_decr: 1,
                    r_incr: 2,
                    r_decr: 1
                },

            },

            kids_ctw_sounds: {
                label: "CTW Kids Sounds (beginner)",
                level: 5,
                eda: true,
                adjustVolume: true,
                type: "Waves",
                params: {
                    tree: true,
                    backgroundImage: "/media/cello.jpg",
                    duration: 300000,
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1,

                    //backgroundSound: "./media/calm.mp3",
                    disturb: [
                        {
                            level: 60,
                            hist: 10,
                            sound: "./media/click.mp3"
                        },
                        {
                            level: 40,
                            hist: 10,
                            sound: "./media/baby1.mp3"
                        },
                        {
                            level: 20,
                            hist: 10,
                            sound: "./media/alert.mp3"

                        },
                        {
                            level: 5,
                            hist: 10,
                            sound: "./media/baby2_short.mp3",

                        }



                    ],
                },

            },

            kids_ctw_tetris: {
                label: "CTW Kids Tetris",
                level: 6,
                eda: true,
                type: "Tetris",
                params: {
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1,

                },

            },

        }

    }
}