import React from 'react';
// import ReactHowler from 'react-howler'
import { Howl, Howler } from 'howler';
import { CalcScore } from '../scripts/calc_score';
import { CalcSessionStats } from '../scripts/calc_sessionstats';
import Wave from 'react-wavify'
import Fab from '@mui/material/Fab';

import Button from '@mui/material/Button';
// import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
// import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import SkipNextIcon from '@mui/icons-material/SkipNext';
// import { ConfirmationNumberOutlined, PlaylistAddCheckCircleSharp } from '@mui/icons-material';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
// import DoneIcon from '@mui/icons-material/Done';
import VolumeUp from '@mui/icons-material/VolumeUp';
import VolumeDown from '@mui/icons-material/VolumeDown';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import ReactPlayer from 'react-player'
import campaign_data from '../scripts/campaign_data';

// import { text_audiomodule_data } from './text_audiomodule_data.js';


// files from https://www.zapsplat.com/ or https://freesound.org/

class VideoTutorial extends React.Component {
  constructor(props) {
    super(props);

    let params = props.params;

    this.campdata = campaign_data();

    this.loaded = false;
    this.success = false;

    this.state = {
      ended: false,
    }
  }

  async ended() {
    if (this.endedCalled) return;
    this.endedCalled = true;

    console.log("Ended");

    let sessionData = {
      ts: this.start,
      success: true
    };

    await this.props.finished_cb(sessionData);
  }

  
  onEda(gsr, acc) {
    // console.log(".")
    return;
  }

  render() {

    let url = this.props.params.url;
    // console.log("URL", url);

    let campaign = this.props.campaign;
    let exercise = this.props.exercise;

    console.log("VideoTutorial render", campaign, exercise); 

    let ex = this.campdata[campaign].exercises[exercise];

    console.log("VideoTutorial", ex);

    return (
      <div>

        <Fab variant="contained" color="primary" >
          <CheckCircleIcon onClick={this.ended.bind(this)} />
        </Fab>

        <br></br>

        <Typography variant="h3">
            {ex.label}
        </Typography>

        <br></br>

        {/* <div style={{ position: 'absolute', left: '0px', top: 60, zIndex: '-10', width: "100%" }}> */}
        <div style={{ width: "100%" }}>

          {/* <Box sx={{ m: 'auto', p: 1, maxWidth: "800px" }}> */}

            <ReactPlayer
              // url="/media/gerisgame.m4v"
              // url="/media/rezilio/cp_instruction.mp4"
              url={url}
              width="640"
              height="360"
              volume="1"
              playing={true}
              controls
              onEnded={this.ended.bind(this)}
            // onStarted={this.started.bind(this)}

            />


            {/* <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.ended.bind(this)}>Finish</Button> */}

          {/* </Box> */}
        </div>



      </div>
    );

  };
}

export default VideoTutorial;