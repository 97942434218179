
import { CalcSessionStats } from './calc_sessionstats';
import { getWeek } from './getWeek';
import features from '../scripts/features';
import campaign_data from './campaign_data';

function isValidEdaSession(s) {
    if (!s.data) return false;
    if (!s.data.eda) return false;
    if (s.data.eda.length < 10) return false;

    if (!s.data.scores) return false;
    if (s.data.scores.length<10) return false;

    let dur = s.data.eda[s.data.eda.length-1][0] - s.data.eda[0][0];
    //if(dur < 60*1000) return false;

    return true;
}

function getStat(ses, levelType) {
    console.log("CALC_LEVEL_GENERIC getStat levelType", levelType);

    let sessions = 0;
    let relax = 0;
    let decr = 0;
    let sustain = 0;
    let trees = 0;
    let flowers = 0;

    let levelsessions = [];
    let levelSucc = [];

    let campdata = campaign_data();

    for (let s of ses) {

        if(! s.campaign ) continue;
        let camp = campdata[s.campaign];
        if(camp.levelType != levelType) continue;
            
        let ex = camp.exercises[s.exercise];

        if(ex === undefined) {
            console.log("EX UNDEFINED", s.exercise);
            continue;
        }

        if (s.eda && (!isValidEdaSession(s))) continue;

        let slevel = ex.level;

        if(!levelsessions[slevel]) {
            
            levelsessions[slevel] = 0;
        }
        levelsessions[slevel] += 1;

        if(!levelSucc[slevel]) levelSucc[slevel] = 0;
        if(s.data.success) levelSucc[slevel] += 1;


        //console.log(s);

        let calcSessionStats = new CalcSessionStats();
        let stats = calcSessionStats.calc_stats(s);

        //console.log(stats);

        if(stats.relaxpts) relax += stats.relaxpts;
        if(stats.decr) decr += stats.decr;
        if(stats.sustain) sustain += stats.sustain;

        sessions += 1;

        if(s.data.creature) {
            if(s.data.creature.type==="tree") {
                if(s.data.creature.data.size >= 50) {
                    trees += 1;
                }
            }

            if(s.data.creature.type==="flower") {
                if(s.data.creature.data.size >= 50) {
                    trees += 1;
                }
            }

        }
    }

    console.log("CALC_LEVEL_GENERIC", sessions, relax, decr, sustain, trees, levelsessions, levelSucc);

    return {
        sessions: sessions,
        relax: relax,
        decr: decr,
        sustain: sustain,
        trees: trees,
        levelsessions: levelsessions,
        levelSucc: levelSucc
    }
}

export function calcLevelGeneric(sessions, campName) {
    // console.log("calcLevelZen");

    console.log("CALC_LEVEL_GENERIC courseLevel", campName);

    let campdata = campaign_data();
    let camp = campdata[campName];
    let exercises = camp.exercises;

    let levelType = camp.levelType;

    // console.log("CALC_LEVEL_GENERIC camp", camp);
    // console.log("CALC_LEVEL_GENERIC ex", exercises);

    let level = 1;
    let res, info;

    let s = getStat(sessions, levelType);
    console.log("CALC_LEVEL_GENERIC levelstat", s);

    let text = false;

    let delta = {}

    let currentLevel = 1;

    let ptext = [];

    for(let exname in exercises) {
        let ex = exercises[exname];

        console.log("CALC_LEVEL_GENERIC", currentLevel, ex);

        // check if ex contains 'level'
        if(!ex.level) continue;
        if(!ex.criteria) {
            currentLevel = ex.level;
            console.log("CALC_LEVEL_GENERIC", currentLevel, "no criteria");
            continue;
        }

        let criteria = ex.criteria;

        console.log("CALC_LEVEL_GENERIC LEVEL CRITERIA", currentLevel, criteria);

        let l = criteria;

        console.log("CALC_LEVEL_GENERIC SS", l.levelSucc);

        delta={};

        text = false;
        if(l.sessions && l.sessions > s.sessions) {
            delta.sessions = l.sessions - s.sessions;
            text = `to do ${l.sessions - s.sessions} more exerise(s)` ;
        }

        if(!s.levelsessions[currentLevel]) s.levelsessions[currentLevel] = 0;
        if(l.n_maxlevel && l.n_maxlevel > s.levelsessions[currentLevel]) {
            delta.n_maxlevel = l.n_maxlevel - s.levelsessions[currentLevel];
            text = `to do ${delta.n_maxlevel} more exercise(s) on this level` ;
        }

        if(!s.levelSucc[currentLevel]) s.levelSucc[currentLevel] = 0;
        if(l.levelSucc && l.levelSucc > s.levelSucc[currentLevel]) {
            delta.levelSucc = l.levelSucc - s.levelSucc[currentLevel];

            if(delta.levelSucc==1) text = `to complete this step` ;
            else text = `to do ${delta.levelSucc} more successful exercises on this level` ;
        }

        if(l.relax && l.relax > s.relax) {
            delta.relax = l.relax - s.relax;
            ptext.push(`${delta.relax} more relax points`);
        }


        if(l.decr && l.decr > s.decr) {
            delta.decr = l.decr - s.decr;
            ptext.push(`${delta.decr} more control points`) ;
        }

        if(l.sustain && l.sustain > s.sustain) {
            delta.sustain = l.sustain - s.sustain;
            ptext.push(`${delta.sustain} more balance points`) ;
        }

        if(l.trees && l.trees > s.trees) {
            delta.trees = l.trees - s.trees;
            ptext.push(`${delta.trees} more flowers / trees`) ;
        }

        if(text || ptext.length>0) break;

        currentLevel = ex.level;

    }

    console.log("CALC_LEVEL_GENERIC", text, ptext);

    if(text) {
        info = "To progress further, you need "+text;
    }

    if(ptext.length>0) {
        console.log("CALC_LEVEL_GENERIC", currentLevel, "ptext", ptext);
        if(text) info += ", and also "
        else info = "To progress further, you need ";

        info += " "+ ptext.join(", ");  
    }

    if(!info) {   
        info = "You have reached the final step!";
        //currentLevel+=1;
    }

    // if(courseLevel && currentLevel>courseLevel) {
    //     currentLevel = courseLevel;
    //     info = "You have reached the maximum level set by your trainer."
    // }
    console.log("CALC_LEVEL_GENERIC l", currentLevel, info, delta, s);

    let next_exercise = false;

    // case when there are no sessions on the level
    if(s.levelsessions[currentLevel] === 0) {
        console.log("CALC_LEVEL_GENERIC LEVEL", currentLevel, "no sessions on level, offer the last one");
        next_exercise = getExerciseFromLevel(campName, currentLevel);
    } else if(s.levelSucc[currentLevel] === 0) {
        console.log("CALC_LEVEL_GENERIC LEVEL", currentLevel, "no successful sessions on level, offer a random", currentLevel, delta);
        next_exercise = getRandomExercise(campName, currentLevel, delta);
    } else {
        console.log("CALC_LEVEL_GENERIC", currentLevel, "offer random sessions from earlier");
        next_exercise = getRandomExercise(campName, currentLevel, delta);
    }


    // features
    let earnedfeatures = [];
    let feat = features();
    // console.log(s);
    for(let f of feat.fg) {
        //console.log(f);

        let pass = true;
        if(f.sessions && s.sessions < f.sessions) pass = false;
        if(f.decr && s.decr < f.decr) pass = false;
        if(f.relax && s.relax < f.relax) pass = false;
        if(f.sustain && s.sustain < f.sustain) pass = false;
        if(f.trees && s.trees < f.trees) pass = false;

        if(pass) {
            earnedfeatures.push(f.id);
        }



    }
    console.log("CALC_LEVEL_GENERIC ", currentLevel, info, earnedfeatures, next_exercise);


    // OVERRRIDE!!!
    // currentLevel = 100;
    // info = "";

    return [currentLevel, info, earnedfeatures, next_exercise];

}

function getExerciseFromLevel(campName, level) {
    let campdata = campaign_data();
    let camp = campdata[campName];
    let exercises = camp.exercises;

    for(let e in exercises) {
        if(exercises[e].level == level) {
            console.log("CALC_LEVEL_GENERIC LEVEL", level, "camp", campName, "exercise", e);
            return [campName, e];
        }
    }

    console.log("CALC_LEVEL_GENERIC LEVEL", level, "exercise not found");
    return false;
}

function getRandomExercise(campName, level, delta) {
    let campdata = campaign_data();
    let camp = campdata[campName];
    let exercises = camp.exercises;

    console.log("CALC_LEVEL_GENERIC LEVEL", level, "delta", delta, "camp", campName);

    let ex = [];
    for(let e in exercises) {
        if(exercises[e].level <= level) {
            ex.push([campName, e]);
        }
    }

    console.log("CALC_LEVEL_GENERIC LEVEL", level, "random exercise", ex);
    return ex[Math.floor(Math.random() * ex.length)];
}