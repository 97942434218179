import React from 'react';
import campaign_data from '../scripts/campaign_data';

import Divider from '@mui/material/Divider';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TopicIcon from '@mui/icons-material/Topic';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import VideocamIcon from '@mui/icons-material/Videocam';
import Exercise from './Exercise';
import CircularProgress from '@mui/material/CircularProgress';
// import { TextField } from '@mui/material';
// import { tabsListUnstyledClasses } from '@mui/base';
// import { toHaveStyle } from '@testing-library/jest-dom/matchers';
import { MyContext } from '../MyContext';

export default class Campaigns extends React.Component {
  static contextType = MyContext;

  constructor(props) {
    super(props);

    // console.log("Campaign =================================================");

    // this.edaRef = React.createRef();

    this.campdata = campaign_data();

    // console.log("Exercise_data: ", this.exercise_data);

    // console.log("Campaigns level", props);

    if (props.level) this.level = JSON.parse(props.level);

    // console.log("Campaigns level", this.level);

    this.state = {
      loaded: false,
      open: false,
    }
  }

  resetOpen() {
    let open = [];
    for (let i in this.props.campaigns) {
      open.push(false);
    }

    // console.log("resetOpen:", open);

    this.setState({
      open: open,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) return;

    // console.log("Campaigns update ++++++++", this.props);

    // this.resetOpen();

    if (this.props.level) this.level = JSON.parse(this.props.level);
  }

  componentDidMount() {
    // console.log("componentDidMount resetOpen");
    this.resetOpen();
  }

  handleClick(i) {
    // console.log("Campaign handleClick state:", this.state);

    // deep copy 
    let s = Array.from(this.state.open);

    s[i] = !s[i];

    //this.state.open[i] = !this.state.open[i];

    // console.log("handleClick:", s);

    this.setState({ open: s });

  }

  exerciseSelected(campname, exname) {
    console.log("Campaign selected: ", campname, "exercise:", exname);
    this.setState({ exerciseSelected: exname, campaignSelected: campname });

    this.props.exerciseSelectedCb(campname, exname);
  }

  // exerciseFinished() {
  //   this.setState({ exerciseSelected: false });
  // }

  // onEda(gsr, acc) {
  //   console.log("Campaign: onEda");

  //   // passing further to Exercise
  //   if(this.edaRef.current) this.edaRef.current.onEda(gsr, acc)
  // }

  render() {

    console.log("USAGE: ", this.context.usageLimited);
    let usageLimited = this.context.usageLimited;

    if (!this.level) {
      console.log("No level info provided to Capmaign");
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>);
    }

    let c = [];

    // console.log("campaigns available for this user: ", this.props.campaigns);

    for (let i in this.props.campaigns) {
      let campname = this.props.campaigns[i];
      let camp = false;

      if (!(campname in this.campdata)) {
        console.log("Error unknown campaign", campname);
        continue;
      }

      camp = this.campdata[campname];

      if (camp.group && this.context.mentorView
        || (!camp.group && !this.context.mentorView)) {


        // c.push(
        //   <ListItemButton key={campname} onClick={this.handleClick.bind(this, i)}>
        //     <ListItemIcon key={campname + "icon"} >
        //       <TopicIcon key={campname + "icon2"} />
        //     </ListItemIcon>
        //     <ListItemText key={campname + "label"} primary={camp.label} />

        //     {this.state.open[i] ? <ExpandLess /> : <ExpandMore />}

        //   </ListItemButton>

        // );

        c.push(
          <ListItemButton key={campname} onClick={this.handleClick.bind(this, i)}>
            <ListItemIcon>
              <TopicIcon />
            </ListItemIcon>
            <ListItemText primary={camp.label} />
            {this.state.open[i] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        );

      }

      let notReached = 0;

      // console.log("///////////////// CAMPID", campid);
      // console.log(camp.exercises);

      let e = [];
      for (let exname in camp.exercises) {

        let ex = camp.exercises[exname];

        let mylevel;
        if (campname === "experimental") {
          mylevel = 100;
        } else if (campname === "spider") {
          mylevel = 100;
        } else if (this.context.mentorView) {
          mylevel = 100;
        } else {
          if (!this.level) continue;
          if (!this.level[campname]) continue;
          mylevel = this.level[campname].level;

        }

        if (ex.section) {

          if (notReached < 1) {
            // e.push(
            //   <Divider />
            // )

            // e.push(
            //   <ListSubheader>{ex.section} </ListSubheader>
            //   // <ListItemText primary={ex.section} />
            // )

            e.push(
              <Divider key={`${ex.section}_divider`} />
            );
            
            e.push(
              <ListSubheader key={`${ex.section}_subheader`}>{ex.section}</ListSubheader>
            );
          }

          continue;
        }

        // console.log("MYLEVEL", mylevel, this.level);89

        let label = ex.label;
        let seclabel = false;
        seclabel = `step ${ex.level}`;

        let available = true;
        // if (mylevel < ex.level && (this.props.user !== "root")) {
        if (mylevel < ex.level) {
          notReached += 1;

          if (notReached == 1) {
            available = false;
            seclabel = " you have not reached this level";
          } else {
            label = "..."
            seclabel = "";
            continue;

          }
        }

        if (ex.inactive == true) continue;

        if (usageLimited) {
          available = false;
          seclabel = "daily usage limit reached";
        }

        let icon = <PlayCircleIcon />;
        if (ex.type === "VideoTutorial") {
          icon = <VideocamIcon />;
        }

        if (this.context.mentorView) {
          seclabel = null;
        }

        let key = campname + "_" + ex.label;
        // console.log("XXXkey", key);

        e.push(
          <ListItemButton disabled={!available} key={key} sx={{ pl: 5 }} onClick={this.exerciseSelected.bind(this, campname, exname)}>
            <ListItemIcon>
              {icon}
            </ListItemIcon>
            <ListItemText primary={label} secondary={seclabel} />
          </ListItemButton>

        )
      }

      c.push(
        <Collapse key={campname + "_collapse"} in={this.state.open[i]} timeout="auto" unmountOnExit>
          <List disablePadding>
            {e}
          </List>
        </Collapse>
      );
      

    }

    return (
      <Box maxWidth='90%' margin={3} alignItems="center"
        justifyContent="center" display="flex">

        <List
          sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}
          component="nav"
          subheader={
            < ListSubheader  >
              <Typography variant="h5">
                Library of exercises
              </Typography>
            </ListSubheader >
          }
        >
          {c}
        </List>


      </Box>
    );

  }
}
