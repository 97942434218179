import React, { Component } from 'react';
import Wave from 'react-wavify';
import { InputLabel, FormControl, OutlinedInput, Button } from '@mui/material';
import Typography from '@mui/material/Typography';

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      password: '',
      showRegistration: false,
      email: '',
      registrationCode: ''
    };
  }

  handleInputChange(type, event) {
    this.setState({
      [type]: event.target.value
    });
  }

  onSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('/authenticate', {
        method: 'POST',
        body: JSON.stringify({ user: this.state.user, password: this.state.password }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        console.log("LOGIN response:", data);
        this.props.onLogin(data);
      } else {
        console.log("LOGIN response:", response);
        alert('Login failed: ' + response);
      }
    } catch (err) {
      console.error(err);
      alert('Error logging in. Please try again.');
    }
  };

  onRegister = async (event) => {
    event.preventDefault();
    try {
    
      console.log("email:", this.state.email, "user:", this.state.user, "password:", this.state.password, "code:", this.state.registrationCode);  
      const response = await fetch('/api/register_with_code', {
        method: 'POST',
        body: JSON.stringify({
          nickname: this.state.nickname,
          user: this.state.email,
          password: this.state.password,
          code: this.state.registrationCode
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = await response.text();
      if (response.ok) {
        alert(data);
        this.setState({ showRegistration: false });
      } else {
        alert(`Registration failed: ${data}`);
      }
    } catch (err) {
      console.error(err);
      alert('Error registering user. Please try again.');
    }
  };

  toggleRegistration = () => {
    this.setState((prevState) => ({ showRegistration: !prevState.showRegistration }));
  };

  render() {
    const { showRegistration } = this.state;

    return (
      <div>
        <br></br><br></br><br></br>

        {!showRegistration ? (
          <div>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-user">User</InputLabel>
              <OutlinedInput
                id="outlined-adornment-user"
                type='text'
                autoComplete="username"
                value={this.state.user}
                onChange={this.handleInputChange.bind(this, 'user')}
                label="User name"
              />
            </FormControl>

            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type='password'
                autoComplete="current-password"
                value={this.state.password}
                onChange={this.handleInputChange.bind(this, 'password')}
                label="Password"
              />
            </FormControl>

            <br></br><br></br>

            <Button size="medium" onClick={this.onSubmit} variant="contained">LOGIN</Button>
            <Button size="medium" onClick={this.toggleRegistration} variant="text">REGISTER</Button>
          </div>
        ) : (
          <div>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
              <OutlinedInput
                id="outlined-adornment-email"
                type='email'
                autoComplete="email"
                value={this.state.email}
                onChange={this.handleInputChange.bind(this, 'email')}
                label="Email"
              />
            </FormControl>

            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-registration-code">Registration Code</InputLabel>
              <OutlinedInput
                id="outlined-adornment-registration-code"
                type='text'
                value={this.state.registrationCode}
                onChange={this.handleInputChange.bind(this, 'registrationCode')}
                label="Registration Code"
              />
            </FormControl>

            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-user">Display Name</InputLabel>
              <OutlinedInput
                id="outlined-adornment-user"
                type='text'
                autoComplete="nickname"
                value={this.state.nickname}
                onChange={this.handleInputChange.bind(this, 'nickname')}
                label="Display name"
              />
            </FormControl>

            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type='password'
                autoComplete="new-password"
                value={this.state.password}
                onChange={this.handleInputChange.bind(this, 'password')}
                label="Password"
              />
            </FormControl>

            <br></br><br></br>

            <Button size="medium" onClick={this.onRegister} variant="contained">REGISTER</Button>
            <Button size="medium" onClick={this.toggleRegistration} variant="text">BACK TO LOGIN</Button>
          </div>
        )}

        <div className="intro-wave">
          <Wave fill='#B289EF'
            paused={false}
            options={{
              height: 20,
              amplitude: 30,
              speed: 0.15,
              points: 7
            }}
            style={{ position: 'absolute', left: '0px', top: '90%', zIndex: '1', height: "100%" }}
          ></Wave>

          <Wave fill='rgba(150, 97, 255, .8)'
            paused={false}
            options={{
              height: 20,
              amplitude: 30,
              speed: 0.15,
              points: 5
            }}
            style={{ position: 'absolute', left: '0px', top: '90%', zIndex: '2', height: "100%" }}
          ></Wave>
        </div>

        <br></br><br></br>

        <Typography variant="body2" color="text.secondary">
          <a href="https://obimon.com">obimon.com</a>
        </Typography>

        <br></br>

        <Typography variant="body2" color="text.secondary">
          Contact us: <a href="mailto:info@obimon.com">info@obimon.com</a>
        </Typography>

      </div>
    );
  }
}
