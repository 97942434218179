import React, { Component } from 'react';

export default class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user : '',
      password: ''
    };
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  }


  async onSubmit(event) {
    event.preventDefault();

    console.log("BACKEND Register submit");

    let resp = await fetch('/api/register', {
      method: 'POST',
      body: JSON.stringify(this.state),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    console.log(resp);

    const text = await resp.text();

    console.log(text);

    if(text === "user_exists") alert("ERROR: User already exists!");
    if(text === "user_added") alert("SUCCESS!");
  }

  render() {
    return (
      <form onSubmit={this.onSubmit.bind(this)}>
        <h1>Register:</h1>
        <input
          type="text"
          name="user"
          autoComplete="off"
          placeholder="Enter user name"
          value={this.state.user}
          onChange={this.handleInputChange}
          required
        />
        <input
          type="password"
          name="password"
          autoComplete="off"
          placeholder="Enter password"
          value={this.state.password}
          onChange={this.handleInputChange}
          required
        />
        <input type="submit" value="Submit"/>
      </form>
    );
  }
}