
import { calcLevelCTW } from './calc_level_ctw';
// import { calcLevelCTWkids } from './calc_level_ctw_kids';
// import { calcLevelZen } from './calc_level_zen';
// import { calcLevelAudioSessions } from './calc_level_audio';
// import { calcLevelChill } from './calc_level_chill';
// import { calcLevelRezilio } from './calc_level_rezilio';
// import { calcLevelRezilia } from './calc_level_rezilia';


// in JS objects insertion order is preserved! so dicts act like lists!!!!

export const ctw_data = {
    ctw: {        
        label: "Calm The Waves",
        levelType: "CTW",
        levelFn: calcLevelCTW,

        exercises: {
            ctw_basic: {
                label: "CTW beginner",
                level: 1,
                eda: true,
                
                type: "Waves",
                params: {
                    duration: 300000,
                    backgroundImage: "/media/00szinkombinacioJO_5.jpg",
        
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },
        
            },
        
            ctw_0: {
                label: "CTW intermediate",
                level: 2,
                eda: true,
                
                type: "Waves",
                params: {
                    tree: true,
                    backgroundImage: "/media/desert4.jpg",
                    duration: 900000,
                    point_mult: 3,
                    l_incr: 2,
                    l_decr: 1,
                    r_incr: 2,
                    r_decr: 1
                },
        
            },
        
            ctw_sounds: {
                label: "CTW sounds (intermediate)",
                level: 3,
                eda: true,
                adjustVolume: true,
               
                type: "Waves",
                params: {
                    tree: true,
                    backgroundImage: "/media/cello.jpg",
                    duration: 900000,
                    point_mult: 6,
                    l_incr: 2,
                    l_decr: 1,
                    r_incr: 2,
                    r_decr: 1,
        
                    //backgroundSound: "./media/calm.mp3",
                    disturb: [
                        {
                            level: 60,
                            hist: 10,
                            sound: "./media/click.mp3"
                        },
                        {
                            level: 40,
                            hist: 10,
                            sound: "./media/baby1.mp3"
                        },
                        {
                            level: 20,
                            hist: 10,
                            sound: "./media/alert.mp3"
        
                        },
                        {
                            level: 5,
                            hist: 10,
                            sound: "./media/baby2_short.mp3",
        
                        }                        
                    ],
                },
        
            },
        
            ctw_1: {
                label: "CTW advanced",
                level: 4,
                eda: true,
                
                type: "Waves",
                params: {
                    tree: true,
                    backgroundImage: "/media/waterfall.jpg",
                    point_mult: 12,
                    l_incr: 2,
                    l_decr: 0.5,
                    r_incr: 2,
                    r_decr: 0.5
                },
        
            },
                
            ctw_calm: {
                label: "CTW music",
                level: 5,
                eda: true,
                adjustVolume: true,
                
                type: "Waves",
                params: {
                    tree: true,
                    backgroundImage: "/media/audience2.jpg",
                    point_mult: 24,
                    l_incr: 2,
                    l_decr: 0.5,
                    r_incr: 2,
                    r_decr: 0.5,
        
                    backgroundSound: "./media/calm.mp3",
                },
        
            },
        
            ctw_shepard: {
                label: "CTW Shepard",
                level: 5,
                eda: true,
                adjustVolume: true,
                            
                type: "Waves",
                params: {
                    tree: true,
                    backgroundImage: "/media/fractal3.jpg",
                    point_mult: 24,
                    l_incr: 2,
                    l_decr: 0.5,
                    r_incr: 2,
                    r_decr: 0.5,
        
                    backgroundSound: "./media/shepard.mp3",
                },
        
            },
        
            ctw_tetris: {
                label: "Tetris (basic)",
                level: 1,
                eda: true,
                
                type: "Tetris",
                params: {
                    showWaves: true, 
        
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1,
        
                },
        
            },
        }
    }
}
