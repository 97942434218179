
// import { calcLevelCTW } from './calc_level_ctw';
// import { calcLevelCTWkids } from './calc_level_ctw_kids';
// import { calcLevelAudioSessions } from './calc_level_audio';
// import { calcLevelChill } from './calc_level_chill';
// import { calcLevelRezilio } from './calc_level_rezilio';
// import { calcLevelRezilia } from './calc_level_rezilia';

import { zen_data } from './zen_data.js';
import { chill_data } from './chill_data.js';
import { ctw_data } from './ctw_data.js';
import { kids_ctw_data } from './kids_ctw_data.js';
import { audiomodule_data } from './audiomodule_data.js';
import { rezilio_data } from './rezilio_data.js';
import { rezilia_data } from './rezilia_data.js';
import { cbt_stress_data } from './cbt_stress_data.js';
import { self_awareness_data } from './self_awareness_data.js';
import { cbt_stress_grp_data } from './cbt_stress_grp_data.js';
import { shooting_data } from './shooting_data.js';
import { stress_profiling_1_data } from './stress_profiling_1_data.js';
import { experimental_data } from './experimental_data.js';
import { imagine_data } from './imagine_data.js';


export default function campaign_data() {

    // var all_data = Object.assign({}, ctw_data, audiomodule_data, kids_ctw_data, zen_data, chill_data, rezilio_data, rezilia_data);
    var all_data = Object.assign({}, stress_profiling_1_data, imagine_data, shooting_data, self_awareness_data, cbt_stress_data, cbt_stress_grp_data, ctw_data, zen_data, kids_ctw_data, chill_data, rezilio_data, rezilia_data, experimental_data);

    // console.log("ctw_data", ctw_data);

    // console.log("Campaign data", all_data);

    return all_data;

}

export function findCampHack(exname) {
    // console.log("findCampHack:", exname);

    let campdata = campaign_data();
    for(let campname in campdata) {
        let camp = campdata[campname];

        if(exname in camp.exercises) {
            // console.log("FOUND EX", campname, exname);
            return campname;
        }
    }

    console.log("findCampHack NOT FOUND", exname);
    return false;
}



