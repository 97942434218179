// import { render } from '@testing-library/react';
import React from 'react';
// import Waves2Phase from '../sessions/Waves2Phase';
// import WaveBasic from '../sessions/WaveBasic';
import Waves from '../sessions/Waves';
import WaveReinforce from '../sessions/WaveReinforce';
import WavesSubliminal from '../sessions/WavesSubliminal';
import WaveUpdown from '../sessions/WaveUpdown';
import ImageHabituation from '../sessions/ImageHabituation';
import ShrinkImage from '../sessions/ShrinkImage';
import GetImages from '../GetImages';
import Racing from '../sessions/Racing';

import FeedbackSlider from '../sessions/FeedbackSlider';
import VideoEda from '../sessions/VideoEda';
import VideoTutorial from '../sessions/VideoTutorial';

import AudioSession from '../sessions/AudioSession';
import TextSession from '../sessions/TextSession';
import AudioModule from '../sessions/AudioModule';

import AdjustVolume from '../AdjustVolume';
import AdjustDifficulty from '../AdjustDifficulty';

import Tetris from '../sessions/Tetris';

import RawEda from '../sessions/RawEda';
import Story from '../sessions/Story';

import Keywords from '../sessions/Keywords';
import WaveKeywords from '../sessions/WaveKeywords';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import WaveResultCard from '../WaveResultCard';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import CircularProgress from '@mui/material/CircularProgress';

// import exercise_data from '../scripts/exercise_data';
import campaign_data from '../scripts/campaign_data';
import ReactPlayer from 'react-player';
import instruction_data from '../scripts/instruction_data';

// import { Link } from 'react-router-dom';

import { MyContext } from '../MyContext';
import { ThreeSixty } from '@mui/icons-material';

// var _ = require('lodash');


class Exercise extends React.Component {
  static contextType = MyContext;

  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element

    console.log("++++++++++++++++++++++++++++++++++++++ EXCERCISE +++++++");

    this.finished_cb = this.finished_cb.bind(this);
    this.store_cb = this.store_cb.bind(this);
    this.backto = this.backto.bind(this);

    this.edaRef = React.createRef();
    // this.accRef = React.createRef();

    this.wakelock = false;
    console.log("COnstructor WAKELOCK set false");

    let c = document.cookie;
    let cc = c.split(';');
    let lang = "en";

    // Now take key value pair out of this array
    for (var i = 0; i < cc.length; i++) {
      let name = cc[i].split('=')[0];
      let value = cc[i].split('=')[1];

      if (name === "lang") lang = value;
    }

    this.state = {
      exercise: this.props.exercise,
      campaign: this.props.campaign,
      eda: false,
      volumeAdjusted: false,
      difficultyAdjusted: false,
      lang: lang,

      showResult: false,
      askFeedback: false,
      textAreaValue: "",

    };

    this.startTs = Date.now();

    this.exercise = this.props.exercise;
    this.campaign = this.props.campaign;

    console.log("Campaign:", this.props.campaign);
    console.log("Exercise:", this.props.exercise);

    this.campdata = campaign_data()

    this.exdata = this.campdata[this.campaign].exercises[this.exercise];

    this.instdata = false;

    if (this.props.exercise) {
      this.instdata = this.exdata.instruction;
      this.videoinstdata = this.exdata.video_instruction;
    } else if (this.props.exercise in instruction_data()) {
      console.log("OLD Instruction data found for exercise", this.props.exercise);
      this.instdata = instruction_data()[this.props.exercise];
    } else {
      this.instdata = {
        en: (
          <div>
            Press Start
          </div>
        ),
      }
    }


    // this.instdata = instruction_data()[this.props.exercise];

    console.log("Instdata:", this.instdata);

    if (this.exdata.eda && this.props.connected !== true) {
      this.props.enableBle()
    }

    //console.log("Exercise: ", this.props.exercise, "user:", this.props.user);
    //console.log("Data: ", this.exdata);

    this.bleDevice = {}
  }

  async pollGroupStart() {
    console.log("Polling group start");

    let resp = await fetch('/api/iamwaiting', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ device: this.context.deviceName })
    });

    let d = await resp.json();

    console.log("Group start data", d.started);

    if (d.started === true) {
      console.log("Group started-------------------");
      this.setState({ groupStarted: true });
      clearInterval(this.pollTimer);
    }

  }



  async componentDidMount() {

    if (this.wakelock === false) {
      try {
        this.wakelock = await navigator.wakeLock.request("screen");
        this.wakelock.onrelease = (() => {
          console.log("WAKELOCK RELEASED")
          this.wakelock = false;
        });
      } catch (error) {
        console.error(error);
      }

      console.log("WAKELOCK SET", this.wakelock);
    }

    let hasBle = false;

    try {
      hasBle = await navigator.bluetooth.getAvailability();
    } catch (error) {
      console.error(error);
    }

    this.setState({ hasBle: hasBle, hasBleReturned: true });
    console.log("hasBle " + hasBle);
  }

  componentDidUpdate() {
    //console.log("Component updated" + JSON.stringify(this.props));
  }

  componentWillUnmount() {

    if (this.wakelock !== false) {
      try {
        this.wakelock.release("screen");
        console.log("WAKELOCK RELEASED");
      } catch (error) {
        console.error(error);
      }
    }

    if (this.pollTimer) {
      clearInterval(this.pollTimer);
    }

    //console.log("componentWillUnmount============================");
    if (!this.bleDevice) {
      return;
    }
    if (!this.bleDevice.gatt) return;
    console.log('Disconnecting from Bluetooth Device...');
    if (this.bleDevice.gatt.connected) {
      this.bleDevice.gatt.disconnect();
    } else {
      console.log('> Bluetooth Device is already disconnected');
    }
  }

  onEda(gsr, acc) {
    // console.log("Exercise: onEda");

    // passing further to individual exercises finally
    if (this.edaRef.current) this.edaRef.current.onEda(gsr, acc);
  }

  onAcc(acc) {
    // console.log("OnAcc", acc);
    if (this.edaRef.current) {
      if (this.edaRef.current.onAcc) this.edaRef.current.onAcc(acc);
    }
  }


  onVolumeAdjusted() {
    this.setState({ volumeAdjusted: true });
  }

  onDifficultyAdjusted(d) {
    console.log("onDifficultyAdjusted ", d);

    if (d === "basic") {
      this.exdata.params.difficulty = d;
      this.exdata.params.l_incr = 1;
      this.exdata.params.l_decr = 1;
      this.exdata.params.r_incr = 1;
      this.exdata.params.r_decr = 1;
    }

    if (d === "medium") {
      this.exdata.params.difficulty = d;
      this.exdata.params.l_incr = 2;
      this.exdata.params.l_decr = 1;
      this.exdata.params.r_incr = 2;
      this.exdata.params.r_decr = 1;
    }

    if (d === "advanced") {
      this.exdata.params.difficulty = d;
      this.exdata.params.l_incr = 2;
      this.exdata.params.l_decr = 0.5;
      this.exdata.params.r_incr = 2;
      this.exdata.params.r_decr = 0.5;
    }

    this.setState({ difficultyAdjusted: true, difficulty: d });
  }

  handleTextChange(event) {
    this.setState({ textAreaValue: event.target.value });
  }


  async storeCreature_cb(type, data, img) {

    console.log("BACKEND Creature store for user: " + JSON.stringify(this.props.user));

    let resp = await fetch('/api/savecreature', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: this.props.user,
        ts: this.startTs,
        type: type,
        data: data,
        img: img
      })
    })
      .then(response => response.json())

    console.log("-------------------------------------- Creature storResult: ", resp);

    return (resp.id);
  }



  async store_cb(data) {

    console.log("EXERCISE STORE_CB: ", data);

    data.feedback = this.state.textAreaValue;

    let sessionRecord = {
      user: this.props.user,
      ts: data.ts,
      device: this.context.deviceName,
      type: this.exdata.type,
      exercise: this.props.exercise,
      campaign: this.props.campaign,
      params: this.exdata.params,
      data: data,
      lang: this.state.lang,
      label: this.context.label
    }

    // check if the exercise is part of a group campaign
    if (this.campdata[this.campaign].group === true) {
      sessionRecord.group = true;
    } else {
      sessionRecord.group = false;
    }

    let sessionId = this.context.storeSession(sessionRecord);

    // console.log("GGGG", this.context.sessions);
    // reload!

    return sessionId;

  }


  async finished_cb(sessionData) {
    console.log("EXERCISE: finished_cb called ", sessionData);

    let skip = false;
    if( (!sessionData.eda) || (sessionData.eda.length === 0) ||
      this.exdata.type === "FeedbackSlider" ||
      this.exdata.type === "VideoTutorial") {

        skip = true;

      }

      this.sessionData = sessionData;

      if(skip) {
        await this.save();
        this.props.finished_cb();

        return;
      }

    this.setState({ showResult: false, askFeedback: true });

    // this.props.finished_cb();

    //this.setState({finished: true});
  }

  async save() {
    this.setState({ showResult: false, saving: true });
    await this.store_cb(this.sessionData);
    this.setState({saving: false});
  }

  backto() {
    this.props.finished_cb();
  }

  async feedback() {
    this.setState({ askFeedback: false });
    await this.save();
    this.setState({ showResult: true });

  }

  async resultShown() {

    this.setState({ showResult: false});
    this.props.finished_cb();
  }

  render() {

    if (this.state.saving) {
      return (
          <div>
              <p>Saving...</p>
              <CircularProgress />
          </div>
      );
  }


    console.log("Exercise props connected", this.props.connected);


    if (this.state.askFeedback) {
      return (
        <Box maxWidth='90%' margin={3} sx={{ flexDirection: 'column' }}>

          <br></br>

          <Typography>
            If you wish, you can enter your thoughts here:
          </Typography>

          <br></br>

          <TextareaAutosize
            aria-label="minimum height"
            minRows={5}

            //placeholder="If you wish, you can enter your thoughts here..."
            value={this.state.textAreaValue}
            onChange={this.handleTextChange.bind(this)}
            style={{ width: '90%' }}
          />

          <br></br>

          <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.feedback.bind(this)}>Continue</Button>

        </Box>
      );
    }

    if (this.state.showResult) {

      //let final_score = this.calc_score.calc_final_score(this.scores);


      //let total = Math.floor(this.state.final_score.total);

      //let rank = this.calcRank(this.state.hist, total);

      //console.log("Show RESULT", this.state.sessionData);

      let ses = {
        exercise: this.props.exercise,
        campaign: this.props.campaign,
        data: this.sessionData,
        ts: Date.now(),
        device: this.context.deviceName,
        user: this.context.user
      }

      console.log("EXERCISE RESULT", ses);

      return (
        <Box maxWidth='90%' margin={3} sx={{ flexDirection: 'column' }}>

          <WaveResultCard session={ses}></WaveResultCard>

          <br></br>

          <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.resultShown.bind(this)}>Continue</Button>

        </Box>
      );
    }

    if (this.exdata.eda && (!this.props.connected) && (!this.state.finished)) {
      return (
        <div>
          <p>Bluetooth connection is lost.</p>
          <Button className="reload-button" variant='contained' color='primary' onClick={this.backto.bind(this)}>Back</Button>
        </div>
      );
    }

    if (this.state.warning) {
      // <Alert severity="error">
      //   <AlertTitle>Error</AlertTitle>
      //   This is an error alert — <strong>check it out!</strong>
      // </Alert>      

      let s = this.state.warningText;

      // if(this.usb) {
      //   s = "Please disconnect the EDA device from USB, and try again!";
      // } else {
      //   s = "EDA battery level is too low. Please charge it first."
      // }


      return (
        <div>
          <br></br>
          {s}
          <br></br>
          <br></br>

          <Button className="reload-button" variant='contained' color='primary' onClick={this.backto.bind(this)}>Cancel</Button>
        </div>
      );
    }

    if (this.exdata.eda) {

      if (this.state.hasBleReturned === true && this.state.hasBle === false) {
        return (
          <div>
            <p>Your device does not have a Bluetooth Low Energy adapter.</p>
          </div>
        );
      }
    }

    if (this.state.finished) {
      return (
        <div>
          <p>
            Congratulations, you have reached the end of this exercise!
          </p>
          <Button className="reload-button" variant='contained' color='primary' onClick={this.backto.bind(this)}>Cancel</Button>
        </div>
      )
    }


    if (this.instdata && (!this.state.instructionShown)) {

      console.log("+++++++++++ ", this.instdata, "++++");

      let inst = this.instdata;
      let text = "";

      let lang = this.state.lang;

      if (lang in inst) {
        console.log("Found language inst for ", lang);
        text = inst[lang];
      } else {
        console.log("NOT Found language inst for ", lang);
        //text = inst.values().next().value;
        text = Object.values(inst)[0];
        console.log("inst", inst);
      }

      let langButtons = [];
      for (let l in inst) {
        console.log("langs", l);
        langButtons.push(
          <Button key={'langs' + l} variant='text' color='primary' onClick={
            () => {
              this.setState({ lang: l });
              document.cookie = "lang=" + l;
            }
          }>{l}</Button>

        );
      }

      let vidurl = false;
      let video = false;
      if (this.videoinstdata) {
        if (lang in this.videoinstdata) {
          console.log("Found language video for ", lang);
          vidurl = this.videoinstdata[lang];
        } else {
          console.log("NOT Found language video for ", lang);
          //text = inst.values().next().value;
          vidurl = Object.values(this.videoinstdata)[0];
        }

        console.log("vidurl", vidurl);

        video = <ReactPlayer
          // url="/media/gerisgame.m4v"
          // url="/media/rezilio/cp_instruction.mp4"
          // url={url}
          url={vidurl}
          width="640"
          height="360"
          volume="1"
          playing={true}
          controls
        // onEnded={this.ended.bind(this)}
        // onStarted={this.started.bind(this)}
        />

      }

      console.log("COOKIE", document.cookie);


      return (
        <div key="ex_div">
          <Box sx={{ m: 'auto', maxWidth: "800px" }}>
            {langButtons}
            <br></br>
            <Typography variant="h4" color="text.secondary">
              {this.exdata.label}
            </Typography>

            <br></br>

            {video}

            <br></br>

            {text}

            <br></br>

            <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={
              () => {
                this.setState({ instructionShown: true })
              }
            }>Start</Button>

            <Button className="reload-button" variant='outlined' color='primary' onClick={this.backto.bind(this)}>Cancel</Button>

          </Box>

        </div>
      );
    }

    // if (this.exdata.eda === true) {
    //   return (
    //     <div>Hello</div>
    //   );
    // }

    if (this.exdata.adjustVolume === true && this.state.volumeAdjusted === false) {
      console.log("Need to adjust volme");

      return (
        <AdjustVolume onVolumeAdjusted={this.onVolumeAdjusted.bind(this)}> </AdjustVolume>
      );
    }

    if (this.exdata.adjustDifficulty === true && this.state.difficultyAdjusted === false) {
      console.log("Need to adjust difficulty");

      return (
        <AdjustDifficulty onDifficultyAdjusted={this.onDifficultyAdjusted.bind(this)}> </AdjustDifficulty>
      );
    }

    if (this.campdata[this.campaign].group === true) {

      if (!this.pollTimer) this.pollTimer = setInterval(this.pollGroupStart.bind(this), 1000);

      if (!this.state.groupStarted) {
        return (
          <div>
            <Typography variant="h4" color="text.secondary">
              {this.exdata.label}
            </Typography>
            <br></br>
            Waiting for the trainer to start the exercise...
            <br></br>
            <br></br>
            <CircularProgress />
            <br></br>
            <Button className="reload-button" variant='outlined' color='primary' onClick={this.backto.bind(this)}>Cancel</Button>
          </div>
        );

      } else {
        if (this.pollTimer) clearInterval(this.pollTimer);
      }
    }

    const exTypes = {
      "Waves": Waves,
      "WavesSubliminal": WavesSubliminal,
      "WaveReinforce": WaveReinforce,
      "WaveUpdown": WaveUpdown,
      "ImageHabituation": ImageHabituation,
      "Racing": Racing,
      "FeedbackSlider": FeedbackSlider,
      "VideoEda": VideoEda,
      "VideoTutorial": VideoTutorial,

      "ShrinkImage": ShrinkImage,
      // "WaveBasic": WaveBasic,
      "WaveKeywords": WaveKeywords,
      "Keywords": Keywords,
      "Audio": AudioSession,
      "Text": TextSession,
      "AudioModule": AudioModule,
      "Tetris": Tetris,
      "RawEda": RawEda,
      "Story": Story


    }

    if (this.exdata.type in exTypes) {
      console.log("Exercise type: ", this.exdata.type);
      const ext = exTypes[this.exdata.type];
      const ExtComponent = exTypes[this.exdata.type];

      return (
        <ExtComponent
          exercise={this.state.exercise}
          campaign={this.state.campaign}
          finished_cb={this.finished_cb}
          store_cb={this.store_cb}
          storeCreature_cb={this.storeCreature_cb.bind(this)}
          ref={this.edaRef}
          params={this.exdata.params}
        />
      );

    }

    return (
      <div>
        Error: exercise not found!
      </div>
    );

    //{this.sectionMap[currentSection]()}
  };
}

export default Exercise;
