
// import "./TetrisTest.css"

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CircularProgress, Typography } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Wave from 'react-wavify'

import WaveResultCard from '../WaveResultCard';
import { CalcScore } from '../scripts/calc_score';
import { CalcSessionStats } from '../scripts/calc_sessionstats';

const React = require('react');
//const Tetris = require('react-tetris');
var _ = require('lodash');


var didreset = false;
// var lost = false;
var tetris_points = 0;

class Tetris extends React.Component {

    constructor(props) {
        super(props);

        console.log("TETRIS - CONSTRUCTOR");

        this.showWaves = true;
        if (props.params.showWaves === false) this.showWaves = false;

        console.log("PARAMS:", props);

        this.state = {
            sclScore: 100,
            scrScore: 100,
        }

        this.keypressed = this.keypressed.bind(this);

        this.canvas = React.createRef()
        this.grid = 32;
        this.tetrominoSequence = [];
        // keep track of what is in every cell of the game using a 2d array
        // tetris playfield is 10x20, with a few rows offscreen
        this.playfield = [];

        for (let row = -2; row < 20; row++) {
            this.playfield[row] = [];

            for (let col = 0; col < 10; col++) {
                this.playfield[row][col] = 0;
            }
        }

        this.tetrominos = {
            'I': [
                [0, 0, 0, 0],
                [1, 1, 1, 1],
                [0, 0, 0, 0],
                [0, 0, 0, 0]
            ],
            'J': [
                [1, 0, 0],
                [1, 1, 1],
                [0, 0, 0],
            ],
            'L': [
                [0, 0, 1],
                [1, 1, 1],
                [0, 0, 0],
            ],
            'O': [
                [1, 1],
                [1, 1],
            ],
            'S': [
                [0, 1, 1],
                [1, 1, 0],
                [0, 0, 0],
            ],
            'Z': [
                [1, 1, 0],
                [0, 1, 1],
                [0, 0, 0],
            ],
            'T': [
                [0, 1, 0],
                [1, 1, 1],
                [0, 0, 0],
            ]
        };

        this.colors = {
            'I': 'cyan',
            'O': 'yellow',
            'T': 'purple',
            'S': 'green',
            'Z': 'red',
            'J': 'blue',
            'L': 'orange'
        };

        this.count = 0;
        this.tetromino = this.getNextTetromino();
        this.rAF = null;  // keep track of the animation frame so we can cancel it
        this.gameOver = false;


        this.speed = 800;
        this.start = Date.now();
        this.lastSpeedChange = this.start;
        this.lastSkipped = this.start;

        this.eda = [];
        this.scores = [];

        this.col1 = [178, 137, 239, 1];
        this.col2 = [150, 97, 255, 0.8];

        this.color1 = this.rgba(this.col1);
        this.color2 = this.rgba(this.col2);

        this.tetrisBgColor = this.rgba([0,0,0,0.1]);


        this.id = _.uniqueId("prefix-");

        this.calc_score = new CalcScore(props.params);
        this.calc_stats = new CalcSessionStats();

    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keypressed, true);

    }

    componentDidMount() {
        console.log("componentDidMount");
        didreset = false;
        // lost = false;
        tetris_points = 0;

        // window.addEventListener('keydown', (e) => this.keypressed.bind(this, e), true);
        // window.addEventListener('click', (e) => this.clicked.bind(this, e));

        // element.removeEventListener("mousedown", handleMouseDown, true); // Succeeds

        // window.addEventListener("keydown", function(ev){
        //     this.keypressed(ev);
        //     // console.log("DDDDD", ev);
        // }.bind(this), false);

        window.addEventListener('keydown', this.keypressed, true);

        this.rAF = requestAnimationFrame(this.loop.bind(this));
    }

    clicked(e) {
        console.log("CLICK");
    }

    keypressed = (e) => {
        console.log("KEY", e.which);

        if (this.gameOver) return;

        // left and right arrow keys (move)
        if (e.which === 37 || e.which === 39) {
            console.log("LEFT-RIGHT");

            const col = e.which === 37
                ? this.tetromino.col - 1
                : this.tetromino.col + 1;

            if (this.isValidMove(this.tetromino.matrix, this.tetromino.row, col)) {
                this.tetromino.col = col;
            }
        }

        // up arrow key (rotate)
        if (e.which === 38) {
            console.log("ROTATE");
            let matrix = this.rotate(this.tetromino.matrix);
            if (this.isValidMove(matrix, this.tetromino.row, this.tetromino.col)) {
                this.tetromino.matrix = matrix;
            }
        }

        // down arrow key (drop)
        if (e.which === 40) {
            console.log("DROP");

            let row = this.tetromino.row + 1;

            if (!this.isValidMove(this.tetromino.matrix, row, this.tetromino.col)) {
                this.tetromino.row = row - 1;

                this.placeTetromino();
                return;
            }

            this.tetromino.row = row;
        }

    }

    rgba(c) {
        return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${c[3]})`
    }

    onEda(gsr, acc) {
        if (this.state.ended) {
            return;
        }

        // console.log("++++++++++++++ onEDA");

        let ts = Date.now();

        this.eda.push([ts, gsr]);

        let [sl, sr] = this.calc_score.calc_one(ts, gsr);

        if (this.test) {
            sl = this.simscore;
            sr = sl;

            console.log("SIMSCORE", this.simscore);
        }

        this.calc_stats.calc_one(ts, sl, sr);
        this.scores.push([ts, sl, sr]);

        this.setState({ sclScore: sl, scrScore: sr });

        let score = Math.max(sl, sr);

        var dt = Date.now() - this.start;

        if (this.gameOver) {
            console.log("AAA LOST!");

            // to avoid duplicate calls
            if (!this.ended_called) {

                this.ended_called = true;

                this.setState({  ended: true });

                this.ended();

                return;
            }

            console.log("Duplicate call, ignore!!!!!!!!!");

        }

        // console.log("++++++++++++++ onEDA ==");

    }

    async ended() {
        console.log("SAVE");

        this.setState({ saving: true, askFeedback: false });

        let sessionData = {
            ts: this.start,
            eda: this.eda,
            scores: this.scores,
            feedback: this.state.textAreaValue,
            points: tetris_points,

        };

        let sessionId = await this.props.store_cb(sessionData);

        console.log("**************************** Session ID:", sessionId);

        //Now read back
        // await fetch

        await this.props.finished_cb(sessionData);

    }

    handleTextChange(event) {
        this.setState({ textAreaValue: event.target.value });

    }

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);

        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    generateSequence() {
        const sequence = ['I', 'J', 'L', 'O', 'S', 'T', 'Z'];

        while (sequence.length) {
            const rand = this.getRandomInt(0, sequence.length - 1);
            const name = sequence.splice(rand, 1)[0];
            this.tetrominoSequence.push(name);
        }
    }

    getNextTetromino() {
        if (this.tetrominoSequence.length === 0) {
            this.generateSequence();
        }

        const name = this.tetrominoSequence.pop();

        console.log("getNextTetronimo name:", name);

        const matrix = this.tetrominos[name];

        // I and O start centered, all others start in left-middle
        const col = this.playfield[0].length / 2 - Math.ceil(matrix[0].length / 2);

        // I starts on row 21 (-1), all others start on row 22 (-2)
        const row = name === 'I' ? -1 : -2;

        return {
            name: name,      // name of the piece (L, O, etc.)
            matrix: matrix,  // the current rotation matrix
            row: row,        // current row (starts offscreen)
            col: col         // current col
        };
    }

    rotate(matrix) {
        const N = matrix.length - 1;
        const result = matrix.map((row, i) =>
            row.map((val, j) => matrix[N - j][i])
        );

        return result;
    }

    isValidMove(matrix, cellRow, cellCol) {
        for (let row = 0; row < matrix.length; row++) {
            for (let col = 0; col < matrix[row].length; col++) {
                if (matrix[row][col] && (
                    // outside the game bounds
                    cellCol + col < 0 ||
                    cellCol + col >= this.playfield[0].length ||
                    cellRow + row >= this.playfield.length ||
                    // collides with another piece
                    this.playfield[cellRow + row][cellCol + col])
                ) {
                    return false;
                }
            }
        }

        return true;
    }


    placeTetromino() {
        for (let row = 0; row < this.tetromino.matrix.length; row++) {
            for (let col = 0; col < this.tetromino.matrix[row].length; col++) {
                if (this.tetromino.matrix[row][col]) {

                    // game over if piece has any part offscreen
                    if (this.tetromino.row + row < 0) {
                        return this.showGameOver();
                    }

                    this.playfield[this.tetromino.row + row][this.tetromino.col + col] = this.tetromino.name;
                }
            }
        }


        // check for line clears starting from the bottom and working our way up
        for (let row = this.playfield.length - 1; row >= 0;) {
            if (this.playfield[row].every(cell => !!cell)) {

                // drop every row above this one
                for (let r = row; r >= 0; r--) {
                    for (let c = 0; c < this.playfield[r].length; c++) {
                        this.playfield[r][c] = this.playfield[r - 1][c];
                    }
                }
            }
            else {
                row--;
            }
        }

        this.tetromino = this.getNextTetromino();
    }

    showGameOver() {
        cancelAnimationFrame(this.rAF);
        this.gameOver = true;

        let canvas = this.canvas.current;
        let context = canvas.getContext('2d')

        context.fillStyle = 'black';
        context.globalAlpha = 0.75;
        context.fillRect(0, canvas.height / 2 - 30, canvas.width, 60);

        context.globalAlpha = 1;
        context.fillStyle = 'white';
        context.font = '36px monospace';
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText('GAME OVER!', canvas.width / 2, canvas.height / 2);
    }

    loop() {

        if(this.gameOver) {
            console.log("Loop: gameover");
            return;
        }

        if (this.canvas == null) {
            console.log("No canvas");
            return;
        }

        if (this.canvas.current == null) {
            console.log("No current canvas");
            return;
        }

        let canvas = this.canvas.current;
        let context = canvas.getContext('2d')


        // canvas.style.height = Math.floor(canvas.height * scale) + "px";
        // canvas.style.width = Math.floor(canvas.width * scale) + "px";

        canvas.height = "640";
        canvas.width = "320"; // grid * 10

        let scale = window.innerHeight / (640 + 20);
        canvas.style.height = Math.floor(canvas.height * scale) + "px";
        canvas.style.width = Math.floor(canvas.width * scale) + "px";
    

        this.rAF = requestAnimationFrame(this.loop.bind(this));
        context.clearRect(0, 0, canvas.width, canvas.height);

        context.fillStyle = this.tetrisBgColor;
        context.fillRect(0, 0, canvas.width, canvas.height);

        // draw the playfield
        for (let row = 0; row < 20; row++) {
            for (let col = 0; col < 10; col++) {
                if (this.playfield[row][col]) {
                    const name = this.playfield[row][col];
                    context.fillStyle = this.colors[name];

                    // drawing 1 px smaller than the grid creates a grid effect
                    context.fillRect(col * this.grid, row * this.grid, this.grid - 1, this.grid - 1);
                }
            }
        }

        // draw the active tetromino
        if (this.tetromino) {

            // tetromino falls every 35 frames
            if (++this.count > 35) {
                this.tetromino.row++;
                this.count = 0;

                // place piece if it runs into anything
                if (!this.isValidMove(this.tetromino.matrix, this.tetromino.row, this.tetromino.col)) {
                    this.tetromino.row--;
                    this.placeTetromino();
                }
            }

            context.fillStyle = this.colors[this.tetromino.name];

            for (let row = 0; row < this.tetromino.matrix.length; row++) {
                for (let col = 0; col < this.tetromino.matrix[row].length; col++) {
                    if (this.tetromino.matrix[row][col]) {

                        // drawing 1 px smaller than the grid creates a grid effect
                        context.fillRect((this.tetromino.col + col) * this.grid, (this.tetromino.row + row) * this.grid, this.grid - 1, this.grid - 1);
                    }
                }
            }
        }
    }

    render() {

        let a1 = this.state.sclScore;
        let a2 = this.state.scrScore;

        if (a1 > 100) a1 = 100;
        if (a2 > 100) a2 = 100;

        return (
            // <div onKeyDown={this.keypressed.bind(this)} tabIndex="0">
            <div tabIndex="0">

                <div style={{
                    position: "fixed", zIndex: -2,
                    width: "100%", height: "100%", left: 0
                }}>
                    <div style={{
                        marginLeft: "auto", marginRight: "auto", height: "100%"
                    }}>
                        <canvas ref={this.canvas}>
                            <p>Add suitable fallback here.</p>
                        </canvas>
                    </div>
                </div>


                {this.showWaves === true &&
                    <div>

                        <Wave fill={this.color1}
                            paused={false}
                            options={{
                                height: 50,
                                amplitude: a1,
                                speed: 0.15,
                                points: 2
                            }}
                            style={{ position: 'absolute', left: '0px', top: '50%', zIndex: '-4', height: "50%" }}
                        ></Wave>

                        <Wave fill={this.color2}
                            paused={false}
                            options={{
                                height: 50,
                                amplitude: a2,
                                speed: 0.15,
                                points: 10
                            }}
                            style={{ position: 'absolute', left: '0px', top: '50%', zIndex: '-3', height: "50%" }}
                        ></Wave>

                    </div>
                }

            </div>
        )
    }

}

export default Tetris;
