import React from 'react';
// import ReactHowler from 'react-howler'
import { Howl, Howler } from 'howler';

import Button from '@mui/material/Button';
// import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
// import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import SkipNextIcon from '@mui/icons-material/SkipNext';
// import { ConfirmationNumberOutlined, PlaylistAddCheckCircleSharp } from '@mui/icons-material';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
// import DoneIcon from '@mui/icons-material/Done';
import VolumeUp from '@mui/icons-material/VolumeUp';
import VolumeDown from '@mui/icons-material/VolumeDown';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CalcScore } from '../scripts/calc_score';

// import { text_audiomodule_data } from './text_audiomodule_data.js';


// files from https://www.zapsplat.com/ or https://freesound.org/

class FeedbackSlider extends React.Component {
  constructor(props) {
    super(props);

    console.log("Slider constructor ");

    this.loaded = false;

    this.eda = [];
    this.acc = [];

    this.start = Date.now();

    this.state = {
      scale: 0,
      moved: false,
    }
  }


  onEda(gsr, acc) {

    if (this.state.ended) {
      return;
    }

    let ts = Date.now();

    if (!this.lastts) {
      this.lastts = ts;
      return;
    }

    this.eda.push([ts, gsr])
    this.acc.push([ts, acc])

    this.lastts = ts;

  }


  async finish() {

    let sessionData = {
      ts: this.start,
      eda: this.eda,
      acc: this.acc,
      scale: this.state.scale
      // success: this.success
    };

    this.props.finished_cb(sessionData);
  }

  scaleChange(e, newValue) {
    this.setState({ scale: newValue, moved: true });
  }

  render() {

    let disable = true;
    if (this.state.moved) disable = false;

    return (
      <Box sx={{ m: 'auto', p: 1, maxWidth: "800px" }}>
        <br></br>

        <Typography variant="h4" color="text.primary">
          {this.props.params.text}
        </Typography>

        <br></br>

        <Box sx={{ display: 'flex', flexDirection: 'column', p: 'auto', alignItems: 'center' }}>

          <Stack spacing={2} direction="row" sx={{ mb: 1, width: '90%' }} alignItems="center">
            {this.props.params.textLeft}
            <Slider aria-label="Scale" size="large" min={-10} max={10} step={0.1} track={false} value={this.state.scale} onChange={this.scaleChange.bind(this)} />
            {this.props.params.textRight}
          </Stack>

        </Box>



        <Button sx={{ m: 5 }} disabled={disable} variant='contained' color='primary' onClick={this.finish.bind(this)}>Submit</Button>

      </Box>
    );

  };
}

export default FeedbackSlider;