import React from 'react';
import campaign_data from '../scripts/campaign_data';


import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TopicIcon from '@mui/icons-material/Topic';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Exercise from './Exercise';
import CircularProgress from '@mui/material/CircularProgress';
// import { TextField } from '@mui/material';
// import { tabsListUnstyledClasses } from '@mui/base';
// import { toHaveStyle } from '@testing-library/jest-dom/matchers';
import { Button, Card, CardContent, Grid } from '@mui/material';

export default class GroupExerciseControl extends React.Component {

  constructor(props) {
    super(props);

    // console.log("Campaign =================================================");

    this.campdata = campaign_data();

    this.state = {
      waiting: [],
      started: false
    }
  }


  componentDidUpdate(prevProps) {
    if (prevProps === this.props) return;
  }

  componentDidMount() {
    if(this.interval) clearInterval(this.interval);
    this.interval = setInterval(() => this.updateWaitingUsers(), 2000);
  }

  componentWillUnmount() {
    if(this.interval) clearInterval(this.interval);
  }

   // periodiacally update the list of users waiting for the exercise to start by polling the server
  // the response is in the format {"user1": {course: "course1", exercise: "exercise1", "coudevice: "device1"}, ...}}
  // parse the response and update the state
  async updateWaitingUsers() {
    let resp = await fetch('/api/getwaitingusers', { 
      method: 'POST', 
      body: JSON.stringify({
        campaign: this.props.campaign,
        exercise: this.props.exercise
      }),
      headers: {
        'Content-Type': 'application/json'
      }  
    });


    console.log("waiting users response", resp);

    let users = await resp.json();

    let waiting = {};

    console.log("waiting users", users);

    for (let u of Object.keys(users)) {
      waiting[u] = {
        device: users[u].device
      };
    }

    this.setState({ waiting: waiting });

  }

  async handleStartExerciseClicked() {
    console.log("handleStartExerciseClicked");

    let resp = await fetch('/api/startexercise', {
      method: 'POST',
    })

    if(resp.status === 200) {
      this.setState({started: true});
    }

    console.log("start exercise response", resp);


  }


  render() {
    console.log("waiting", this.state.waiting);
    // create a string with the list of users waiting for the exercise to start

    let waitstr = "";
    // pretty print the list of users waiting for the exercise to start
    for (let w of Object.keys(this.state.waiting)) {
      let device = this.state.waiting[w].device;
      if(device !== undefined) waitstr += w + ": " + device + "\n";
    }

    console.log("waitstr", waitstr);

    let camp = this.campdata[this.props.campaign];

    console.log("camp", camp);

    let campName = camp.label;
    let exName = camp.exercises[this.props.exercise].label;

    let s = [];

    // the first card is a full width special card for listing users who are waiting for the exercise to start
    s.push(
      <Grid key="waiting" item xs={12} md={12} lg={12}>
        <Card>
          <CardContent>
            <Typography variant="body1" component="div">
              Users waiting for the exercise to start: 
              <br></br>
              <br></br>
              {campName}/{exName}
              <br></br>
              <br></br>
              <textarea readOnly={true} cols={30} rows={5}
                  value = {waitstr}
              />
            </Typography>

          </CardContent>

        <CardContent>

            <Button disabled={this.state.started} size="small" onClick={this.handleStartExerciseClicked.bind(this)} variant='contained'>
              Start group exercise
            </Button>
          </CardContent>
        </Card>
      </Grid>
    )

    return (
      <Grid container spacing={3} justifyContent='center'>
        {s}
      </Grid>
    );

    

  }
}
